import React, {forwardRef} from 'react';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import ClearIcon from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import Alert from "@material-ui/lab/Alert/Alert";
import ProgressCircle from "./CircularIndeterminate";
import Box from "@material-ui/core/Box";
import TestSetDetail from './TestSetDetail';
import PerfPlot from './PerfPlot';
import CheckIcon from '@material-ui/icons/Check';
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";

const defaultTableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
};

class TestSetTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
    }

    updateItems(result) {
        this.setState({
            isLoaded: true,
            items: result
        });
    }

    componentDidMount() {
        fetch("/api/testsets/")
            .then(res => res.json())
            .then(
                (result) => {
                    this.updateItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error: error
                    });
                })
    }

    render() {
        const {error, isLoaded, items} = this.state;
        if (error) {
            return (
                <Alert severity="error">Error loading test sets. Please try later.</Alert>
            );
        } else if (!isLoaded) {
            return <ProgressCircle/>;
        } else {
            let actions = [];
            let columns = [
                {title: 'Score', field: 'result', type: 'numeric'},
                {title: 'Graphics Card', field: 'gl_vendor', type: 'string'},
                {title: 'Platform', field: 'arch', type: 'string'},
                {title: 'Date', field: 'timestamp', type: 'datetime'},
                {title: 'Comment', field: 'comment', type: 'string', emptyValue: ""},
            ];
            // show the approved column conditionally.
            if (items.length > 0) {
                if (items[0].hasOwnProperty("approved")) {
                    // extra column for admins
                    columns.push(
                        {
                            title: 'Approved',
                            field: 'approved',
                            type: 'boolean',
                            render: rowData => {
                                const approved = rowData.approved;
                                const guid = rowData.hasOwnProperty('guid') ? rowData.guid : '';

                                let href = '/admin-console/core/testset/';
                                if (guid !== '') {
                                    href += `${guid}/change/`;
                                }

                                let icon;
                                if (approved) {
                                    icon = <CheckIcon fontSize='medium'/>
                                } else {
                                    icon = <ClearIcon fontSize='medium'/>
                                }

                                return (
                                    <Link color="inherit" href={href}>
                                        {icon}
                                    </Link>
                                );
                            }
                        }
                    );
                    // extra raw data button.
                    actions = [{
                        icon: OpenInNewRoundedIcon,
                        tooltip: 'Show raw data',
                        onClick: (event, rowData) => {
                            const guid = rowData.hasOwnProperty('guid') ? rowData.guid : '';

                            let href = '/api/testsets/';
                            if (guid !== '') {
                                href += `${guid}/`;
                            }
                            // open in new tab
                            window.open(href, "_blank");
                        }
                    }];
                }
            }

            const detailElements = [
                {
                    icon: InfoRoundedIcon,
                    tooltip: 'Show info',
                    render: rowData => {
                        let plot;
                        if (rowData.hasOwnProperty('tests')) {
                            plot = <PerfPlot graphData={rowData.tests}/>;
                        } else {
                            plot = <div></div>;
                        }
                        return (
                            <Box margin={1}>
                                <Container maxWidth="md">
                                    <TestSetDetail detailContent={rowData}/>
                                </Container>
                                <Container maxWidth="md" className="Plot-container">
                                    {plot}
                                </Container>
                            </Box>
                        );
                    },
                },
            ];
            const options = {
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                emptyRowsWhenPaging: false
            };

            return (
                <MaterialTable icons={defaultTableIcons}
                               title="Test Sets"
                               columns={columns}
                               data={items}
                               detailPanel={detailElements}
                               options={options}
                               actions={actions}
                />
            );
        }
    }
}

export default TestSetTable;