import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ButtonAppBar from './NavBar';
import TestSetTable from './TestSetTable';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
            {'Copyright © '}
            <Link color="inherit" href="https://ansys.com/">
                ANSYS, Inc. 2017-{new Date().getFullYear()}
            </Link>{' | '}
            <Link color="inherit" href="https://www.ansys.com/legal/legal-notices">
                Legal Notices
            </Link>{' | '}
            <Link color="inherit" href="https://www.ansys.com/legal/privacy-notice">
                Privacy Notice
            </Link>{' | '}
            <Link color="inherit" href="https://www.ansys.com/legal/cookie-policy">
                Cookie Policy
            </Link>{' | '}
            <Link color="inherit" href="https://www.ansys.com/legal/terms-and-conditions">
                Terms and Conditions
            </Link>{' | '}
            <Link color="inherit" href="https://www.ansys.com/legal/report-piracy">
                Report Piracy
            </Link>
        </Typography>
    );
}

function App() {
    return (
        <Box width={1}>
            <ButtonAppBar/>
            <Box width={1}>
                <Box width={1} p={2}>
                    <TestSetTable/>
                </Box>
                <Copyright/>
            </Box>
        </Box>
    );
}

export default App;
