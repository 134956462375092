import React from "react";
import Alert from "@material-ui/lab/Alert/Alert";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis, ZAxis
} from "recharts";

const labelStrings = {
    pps: "Millions of Polygons per Second",
    tname: "Test Name",
    np: "Millions of Polygons"
};

const lineColors = [
    '#8884D8',
    '#FFC0CB',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042'
];

const lineShapes = [
    'circle',
    'cross',
    'diamond',
    'square',
    'star',
    'triangle',
    'wye'
];

function PerfBarChart(props) {
    return (
        <ResponsiveContainer>
            <BarChart data={props.data}
                      margin={{top: 15, left: 0, right: 0, bottom: 10}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis type="category" name={labelStrings.tname} dataKey={labelStrings.tname}/>
                <YAxis type="number" name={labelStrings.pps}
                       label={{
                           value: labelStrings.pps,
                           offset: 8,
                           angle: -90, position: 'insideLeft'
                       }}
                />
                <Tooltip/>
                <Bar dataKey={labelStrings.pps}>
                    {
                        props.data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={lineColors[index]} stroke={lineColors[index]}
                                  strokeWidth="2"/>
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

function PerfLineChart(props) {
    const ScatterElements = props.data.map(test => (
        <Scatter name={test.name} data={test.data} fill={test.color} line
                 shape={test.shape} legendType={test.shape}/>
    ));
    return (
        <ResponsiveContainer>
            <ScatterChart margin={{top: 10, left: 0, right: 0, bottom: 15}}>
                <CartesianGrid/>
                <XAxis type="number" dataKey={labelStrings.np} name={labelStrings.np}
                       label={{
                           value: labelStrings.np,
                           offset: -2,
                           angle: 0,
                           position: 'bottom'
                       }}
                />
                <YAxis type="number" dataKey={labelStrings.pps} name={labelStrings.pps}
                       label={{
                           value: labelStrings.pps,
                           offset: 8,
                           angle: -90,
                           position: 'insideLeft'
                       }}
                />
                <ZAxis range={[30]}/>
                <Tooltip cursor={{strokeDasharray: '3 3'}}/>
                <Legend verticalAlign="top" height={36}/>
                {ScatterElements}
            </ScatterChart>
        </ResponsiveContainer>
    );
}

class PerfPlot extends React.Component {
    render() {
        const tests = this.props.graphData;
        let isLine = false;
        let chartDataList = [];

        if (tests.length > 0) {
            //need to check only one
            const {fullflag} = tests[0];
            if (fullflag) {
                isLine = true;
            }

            //build chartData
            for (let i = 0; i < tests.length; i++) {
                const {name, nplist, ppslist} = tests[i];
                if (isLine) {
                    let chartData = [];
                    for (let j = 0; j < nplist.length; j++) {
                        chartData.push(
                            {
                                [`${labelStrings.np}`]: nplist[j],
                                [`${labelStrings.pps}`]: ppslist[j]
                            }
                        );
                    }
                    // the main list
                    chartDataList.push(
                        {
                            "name": name,
                            "data": chartData,
                            "color": lineColors[i],
                            "shape": lineShapes[i]
                        }
                    );
                } else { //bar
                    // the main list
                    chartDataList.push(
                        {
                            [`${labelStrings.tname}`]: name,
                            [`${labelStrings.pps}`]: ppslist[0]
                        }
                    );
                }
            }

            //render
            if (isLine) {
                return (
                    <PerfLineChart data={chartDataList}/>
                )
            } else {
                return (
                    <PerfBarChart data={chartDataList}/>
                )
            }
        } else {
            return (
                <Alert severity="info">Nothing to show here.</Alert>
            );
        }
    }
}

export default PerfPlot;