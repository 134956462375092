import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const classes = {
    root: {
        flexGrow: 1,
    },
};

function Title(props) {
    return (
        <Typography component="div" variant="caption" color="primary" gutterBottom>
            <Box fontWeight="fontWeightBold">
                {props.children}
            </Box>
        </Typography>
    );
}

function Detail(props) {
    return (
        <Typography component="p" variant="h6">
            {props.children}
        </Typography>
    );
}

function DetailCard(props) {
    return (
        <Card margin={1} className={classes.root}>
            <CardContent>
                <Title>{props.title}</Title>
                <Detail>{props.detail}</Detail>
            </CardContent>
        </Card>
    );
}

class TestSetDetail extends React.Component {
    render() {
        const {cpus, ram, pointersize, ensversion, gl_version} = this.props.detailContent;

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <DetailCard title="CPU Count" detail={cpus}/>
                    </Grid>
                    <Grid item xs>
                        <DetailCard title="CPU Type" detail={pointersize}/>
                    </Grid>
                    <Grid item xs>
                        <DetailCard title="RAM" detail={ram}/>
                    </Grid>
                    <Grid item xs>
                        <DetailCard title="EnSight Version" detail={ensversion}/>
                    </Grid>
                    <Grid item xs={4}>
                        <DetailCard title="GL Info" detail={gl_version}/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default TestSetDetail;