import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

function ProgressCircle() {
    return (
        <Container maxWidth="xs">
            <CircularProgress/>
        </Container>
    );
}

export default ProgressCircle;